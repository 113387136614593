/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  Auth: {
    Cognito: {
      userPoolId: "il-central-1_1BgkTr3QQ",
      userPoolClientId: "1vprffs4cr1putq6tvl0om8a4o",
      loginWith: {
        oauth: {
          domain: "pt-planner-dev.auth.il-central-1.amazoncognito.com",
          scopes: ["openid", "email", "phone", "profile", "aws.cognito.signin.user.admin"],
          redirectSignIn: ["https://www.terminals.dev.plana.buspro.org.il/", "http://localhost:3000/"],
          redirectSignOut: ["https://www.terminals.dev.plana.buspro.org.il/", "http://localhost:3000/"],
          responseType: "code",
        },
      },
    },
  },
};

export default awsmobile;
