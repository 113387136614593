// Core React Imports
import React, { useEffect, useRef } from "react";

// Third-Party Libraries
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// Local Imports
import ApiService from "../../data/ApiService";
import ConstantsPages from "../../constants/ConstantsPages";
import { useLoading } from "../../widgets/loadings/WidgetLoading";
import awsmobile from "../../aws-exports-dev";

function PageWelcome() {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const dispatch = useDispatch();

  const showLoadingRef = useRef(showLoading);
  const hideLoadingRef = useRef(hideLoading);

  useEffect(() => {
    // For Development.
    // const response = {
    //   ResponseMetadata: {
    //     RequestId: "ebb55492-763e-4a3d-9224-3e77588a2247",
    //     HTTPStatusCode: 200,
    //     HTTPHeaders: {},
    //     RetryAttempts: 0,
    //   },
    //   UserAttributes: [
    //     { Name: "email", Value: "EliorC@iroads.co.il" },
    //     { Name: "email_verified", Value: "true" },
    //     { Name: "family_name", Value: "Cohen" },
    //     { Name: "given_name", Value: "Elior" },
    //     { Name: "custom:UserTypePlanA", Value: "1" },
    //     { Name: "custom:UserTypeTerminal", Value: "2" },
    //     { Name: "sub", Value: "4a73921c-6011-70b6-b954-77485bda4031" },
    //   ],
    //   Username: "4a73921c-6011-70b6-b954-77485bda4031",
    // };

    // handleSetUserData(response);
    // navigate(ConstantsPages.PAGE_FILTER);

    handleAutorization();
  }, []);

  function handleAutorization() {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");

    if (code) {
      fetchData(code);
    } else {
      login();
    }
  }

  function login() {
    const cognitoMap = awsmobile.Auth.Cognito;
    const oauthMap = cognitoMap.loginWith.oauth;

    const cognitoDomain = oauthMap.domain;
    const responseType = oauthMap.responseType;
    const clientId = cognitoMap.userPoolClientId;
    const redirectUri = window.location.hostname === "localhost" ? oauthMap.redirectSignIn[1] : oauthMap.redirectSignIn[0];
    const scope = oauthMap.scopes.join(" ");

    const queryParams = new URLSearchParams({
      response_type: responseType,
      client_id: clientId,
      redirect_uri: redirectUri,
      scope: scope,
    }).toString();

    const loginUrl = `https://${cognitoDomain}/oauth2/authorize?${queryParams}`;

    window.location.assign(loginUrl);
  }

  async function fetchData(code) {
    showLoadingRef.current();

    try {
      const apiService = new ApiService();
      const fetchedData = await apiService.getUserData(code);

      handleSetUserData(fetchedData);
      navigate(ConstantsPages.PAGE_FILTER);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      hideLoadingRef.current();
    }
  }

  const handleSetUserData = (userData) => {
    dispatch({ type: "SET_USER_DATA", payload: userData });
  };

  return <div />;
}

export default PageWelcome;
